
const digest = 'ec508ba4d18adf0c244183b475e671b8bc81e30d0b06cc9afe791077eb7716d3';
const css = `._deleteWarnings_gthrx_1 {
  padding-top: 1em;
  padding-left: 3px;
}

._deleteWarningsItem_gthrx_6 {
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
}

._deleteItemBullet_gthrx_12 {
  background: var(--color-neutral-40);
  border-radius: 100%;
  height: 0.375em;
  width: 0.375em;
  margin-right: 1em;
}

._deleteNoWarnings_gthrx_20 {
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"deleteWarnings":"_deleteWarnings_gthrx_1","deleteWarningsItem":"_deleteWarningsItem_gthrx_6","deleteItemBullet":"_deleteItemBullet_gthrx_12","deleteNoWarnings":"_deleteNoWarnings_gthrx_20"};
export { css, digest };
  
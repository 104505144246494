
const digest = 'b084d5095be86c269aa65e9cbf026488fde8ead21750c54e4c6fc8a7dc1d4373';
const css = `._timesheetTableActions_hd6fx_1 {
  display: flex;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"timesheetTableActions":"_timesheetTableActions_hd6fx_1"};
export { css, digest };
  

const digest = 'ac0af24593d9fb1e04c3314b355859e4b8537fe156e173596c143ccce64e0d68';
const css = `._alert_16l62_1 {
  --text-color: #a0a8ae;
  --background-color: #606d78;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  color: var(--text-color);
  background: var(--background-color);
  text-align: left;
  padding: 0.6rem;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

._warning_16l62_15 {
  --text-color: rgba(var(--color-red-rgb), 0.75);
  --background-color: rgba(var(--color-red-rgb), 0.25);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"alert":"_alert_16l62_1","warning":"_warning_16l62_15"};
export { css, digest };
  